const MODE = 'PRODUCTION' // DEVELOPMENT , PRODUCTION , STAGING , TESTING

const helper = {

    headers: function (token = '') {
        let apiHeader = {};
        if (token) {
            apiHeader['x-access-token'] = token
        }
        apiHeader['Access-Control-Allow-Origin'] = '*'
        apiHeader['Content-Type'] = 'application/json'
        apiHeader['Accept'] = 'application/json'
        apiHeader['company-code'] = 'vi'
        apiHeader['country'] = '611e04284ac17121fd8b1a54'
        apiHeader['userId'] = localStorage.getItem("persist:root") && JSON.parse(JSON.parse(localStorage.getItem("persist:root"))?.user)?.userDeatils?.user_id

        apiHeader['X-Frame-Options'] = "SAMEORIGIN"
        apiHeader['Strict-Transport-Security'] = "max-age=31536000; includeSubDomains"
       apiHeader["Content-Security-Policy"] =  "frame-ancestors 'none'";
        return apiHeader;

    },

    saasApiUrl: function () {

        let API_BASE_URL = 'http://127.0.0.1:9157/api/'

        if (MODE === 'PRODUCTION') {

            API_BASE_URL = 'https://vi-api-by-eaas-production.gamerji.cloud/api/'
        }
        if (MODE === 'TESTING') {

            API_BASE_URL = 'https://vi-api-by-eaas-testing.gamerji.cloud/api/'
        }
        if (MODE === 'STAGING') {

            API_BASE_URL = 'https://vi-api-by-eaas-staging.gamerji.cloud/api/'
        }
        // API_BASE_URL = 'https://tfm1dd88-9155.inc1.devtunnels.ms/api/' // Updated to use localhost
        return API_BASE_URL;


    },

    saasApiUrl1: function () {

        let API_BASE_URL = 'http://127.0.0.1:9155/api/'

        if (MODE === 'PRODUCTION') {

            API_BASE_URL = 'https://vi-api-by-eaas-production.gamerji.cloud/api/'
        }

        if (MODE === 'STAGING') {

            API_BASE_URL = 'https://vi-api-by-eaas-staging.gamerji.cloud/api/'
        }
        // API_BASE_URL = 'https://tfm1dd88-9155.inc1.devtunnels.ms/api/'
        return API_BASE_URL;


    },


    viApiUrl: function () {

        let API_BASE_URL = 'http://127.0.0.1:9090/api/'

        if (MODE === 'PRODUCTION') {

            API_BASE_URL = 'https://vi-api-production.gamerji.cloud/api/'
        }

        if (MODE === 'TESTING') {
            API_BASE_URL = 'https://vi-api-testing.gamerji.cloud/api/'
            // API_BASE_URL = 'http://127.0.0.1:9090/api/'

        }
        if (MODE === 'STAGING') {
            API_BASE_URL = 'https://vi-api-staging.gamerji.cloud/api/'
            // API_BASE_URL = 'http://127.0.0.1:9090/api/'

        }
        // API_BASE_URL = 'https://tfm1dd88-9090.inc1.devtunnels.ms/api/' // Updated to use localhost
        return API_BASE_URL;


    },
    imageBaseUrl: "https://gamerji-uploads.s3.amazonaws.com/",
    calculatePercentage: function (partialValue, totalValue) {
        return (100 * partialValue) / totalValue;
    },
    paymentTypeDetails: {
        1: 'promotional',
        2: "join league",
        3: "referral",
        4: "refund",
        5: "add balance",
        6: "Withdrawal requested",
        7: "Withdrawal approved",
        8: "Withdrawal request rejected",
        9: "Won the league",
        10: "Deposit Bonus",
        11: "SignUp Codes",
        12: "Penalty or Charges",
        13: "Signup Bonus",
        14: "Duplicate Refunds",
        15: "FE Refunds",
        16: "DailyReward",
        17: "buy gems",
        18: "add gems",
        19: "gemReward Redeem",
        20: "buy gems + Avatar",
        21: "Buy Avatar",
        22: "One time gem bonus",
        23: "earn gem",
        24: "Refund gemReward Redeem"
    },
    addLog: function (key, value) {
        console.log(key, value);
    },

    rerdirectLoginUSer: function (contentId, contextId, otherparameters) {

        let redirectUrl = '/'
        if (contentId && contextId) {
            localStorage.setItem("isAd", true);

            redirectUrl = `/game/join/${contextId}/${contentId}`
        }
        if (contentId && !contextId) {
            localStorage.setItem("isAd", true);

            redirectUrl = `contest/${contentId}`
        }

        if (!contentId && contextId) {
            localStorage.setItem("isAd", true);

            redirectUrl = `afterjoin/${contextId}`
        }

        if (otherparameters === "reward_store") {
            localStorage.setItem("isAd", true);

            redirectUrl = `rewards`
        }
        if (otherparameters === "crm") {
            localStorage.setItem("isAd", true);

            redirectUrl = `customer-care-tickets`
        }
        return redirectUrl

    },
    TEMP_VI_TOKEN: 'U2FsdGVkX19N%2F0VJxTOej4j2NHZNYxXCxChzEi4Kq9%2Bktb8uvSGPZqA8Li2Obx2D',
    TEMP_USER_ID: 'bf7c2b90-90d9-44ea-82f4-7c66676e88f7',
    TicketStatusTitles: ["", "pending", "replied", "in progress", "resolved", "rejected", "closed"],
    cleverTap: "https://in1.api.clevertap.com/1/upload",
    cleverTapAccount: "487-8K4-895Z"



}

export default helper;
